import React from 'react'
import PropTypes from 'prop-types'

import ContentModal from '../ContentModal'

import Body from './Body'

const CustomTermsModal = ({ useModal, customHeader, termsContent }) => {
  return (
    <ContentModal useModal={useModal} header={customHeader ? customHeader : 'Terms and Conditions'}>
      <Body>
        {termsContent ? (
          <div dangerouslySetInnerHTML={{ __html: termsContent }} />
        ) : (
          <p>Default Terms Content</p>
        )}
      </Body>
    </ContentModal>
  );
};

CustomTermsModal.propTypes = {
  useModal: PropTypes.object.isRequired
}

export default CustomTermsModal
