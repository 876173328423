import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useFormikContext } from 'formik'

import { sign_up_initiated_event } from 'gtm/pushEvents'

import { Button, Flex } from 'ui/bend/elements'
import {
  SubscriptionDisclaimer
} from 'ui/bend'

import { Caption } from 'ui/bend/typography'
import InputText from '../../InputText'
import { MarketingDislaimer } from 'ui/chandra'
import { Input } from 'ui/acro'
import { values } from 'regenerator-runtime'

const NameSection = styled(Flex)({
  '& >:first-child': {
    marginRight: '16px'
  }
})

const CheckBoxWrapper = styled(Flex)`
  display: flex;
  align-items: center;
  text-align: unset;
  margin-bottom: ${props => props.mb || 0};
  margin-top: ${props => props.mt || 0};
`

const FullSection = ({
  cta = 'Create Account',
  redirectBack = false,
  setShowSignInModal,
  showTerms=false
}) => {
  const [hasInteracted, setHasInteracted] = useState(false)

  const handleInputFocus = () => {
    if (!hasInteracted) {
      // User has interacted for the first time
      sign_up_initiated_event('email')
      setHasInteracted(true)
    }
  }

  function handleClickSignInError(event, href) {
    event.preventDefault()
    if (typeof setShowSignInModal === 'function') {
      setShowSignInModal(true) // if showSignInModal is present call it
    } else {
      window.location.href = href // if not follow href
    }
  }
  const { isSubmitting } = useFormikContext()
  let signInPath = '/signin'

  if (redirectBack) {
    signInPath += '?redirect=' + encodeURIComponent(window.location.pathname)
  }

  return (
    <Fragment>
      <NameSection>
        <InputText
          type='text'
          name='firstName'
          label='First Name'
          autoComplete='given-name'
          onFocus={() => handleInputFocus()}
        />
        <InputText
          type='text'
          name='lastName'
          label='Last Name'
          autoComplete='family-name'
          onFocus={() => handleInputFocus()}
        />
      </NameSection>
      <InputText
        type='email'
        name='email'
        label='Email'
        autoComplete='email'
        onFocus={() => handleInputFocus()}
        customError={(error) => {
          if (error.includes('has already been taken')) {
            return (
              <Fragment>
                It looks like you already signed up.&nbsp;
                <Caption
                  as='a'
                  onClick={(e) => handleClickSignInError(e, signInPath)}
                  href={signInPath}
                  color='primary'
                  id='sign_in_1'
                >
                  <b>Sign in</b>
                </Caption>
                <Caption as='span'>&nbsp;to your account.</Caption>
              </Fragment>
            )
          } else {
            return error
          }
        }}
      />
      <InputText
        type='password'
        name='password'
        label='Password'
        autoComplete='new-password'
        onFocus={() => handleInputFocus()}
        customError={(error) => {
          if (error.includes('length_error')) {
            return (
              <Fragment>
                Your password must be at least 6 characters long.
              </Fragment>
            )
          } else {
            return error
          }
        }}
      />
      <MarketingDislaimer
        mb={'8px'}
        mt={'8px'}
        checked={values.consent_marketing_email}
      />
      <SubscriptionDisclaimer
        cta={cta}
        hideTerms={!showTerms}
        mt={'8px'}
      />
      <Button
        processing={isSubmitting}
        type='submit'
        width='100%'
        mt='16px'
        id='submit_signup_form'
      >
        {cta}
      </Button>
    </Fragment>
  )
}

FullSection.propTypes = {
  cta: PropTypes.string,
  redirectBack: PropTypes.bool
}

export default FullSection
